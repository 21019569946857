export {};


let internetStatusCheckSource : EventSource | undefined = undefined;

let channel: BroadcastChannel | undefined = undefined;

function initiateInternetStatusEventSource() {
    if (internetStatusCheckSource) {
        return;
    }

    channel = new BroadcastChannel('internet-status');

    internetStatusCheckSource = new EventSource('/sse/internet-status');

    // addEventListener version
    internetStatusCheckSource.addEventListener('open', () => channel?.postMessage({
        type: 'internet-status',
        isOnline: true,
    }));


    // addEventListener version
    internetStatusCheckSource.addEventListener('status', (e) => channel?.postMessage({
        type: 'internet-status',
        isOnline: e.data === 'online',
    }));

    internetStatusCheckSource.addEventListener('error', (e) => {
        channel?.postMessage({
            type: 'internet-status',
            isOnline: false,
        });

        internetStatusCheckSource?.close();
        internetStatusCheckSource = undefined;
        initiateInternetStatusEventSource();
    });
}

self.addEventListener('connect', (event) => {
    initiateInternetStatusEventSource();
});

self.onclose = () => {
    channel?.close();
    internetStatusCheckSource?.close();
};